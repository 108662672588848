import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import store from "@/store";

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      requireLogin: true,
      active: 'home',
      desktop: 'home',
    }
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/users/SignInView.vue')
  },
  {
    path: '/change-password/',
    name: 'change-pwd',
    component: () => import('../views/password/ChangePwdView.vue'),
    meta: {
      requireLogin: true,
      active: 'settings',
      desktop: 'myProfile',
    }
  },
  {
    path: '/reset-password/',
    name: 'reset-pwd',
    component: () => import('../views/password/ForgotPwdView.vue'),
  },
  {
    path: '/password-reset/:uid/:token',
    name: 'pwd-reset',
    component: () => import('../views/password/ResetPwdView.vue'),
  },
  {
    path: '/me',
    name: 'my-profile',
    component: () => import('../views/users/MyProfileView.vue'),
    meta: {
      requireLogin: true,
      active: 'settings',
      desktop: 'myProfile',
    }
  },
  {
    path: '/users',
    name: 'users',
    component: () => import('../views/users/UsersView.vue'),
    meta: {
      requireLogin: true,
      requireAdmin: true,
      active: 'settings',
      desktop: 'users',
    }
  },
  {
    path: '/user/:id',
    name: 'user',
    component: () => import('../views/users/UserView.vue'),
    meta: {
      requireLogin: true,
      requireAdmin: true,
      active: 'settings',
      desktop: 'users',
    }
  },
  {
    path: '/create-user',
    name: 'create_user',
    component: () => import('../views/users/CreateUserView.vue'),
    meta: {
      requireLogin: true,
      requireAdmin: true,
      active: 'settings',
      desktop: 'users',
    }
  },
  {
    path: '/players/:id',
    name: 'player',
    component: () => import('../views/PlayerView.vue'),
    meta: {
      requireLogin: true,
      active: 'settings',
      desktop: 'users',
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requireLogin) && !store.state.isAuthenticated) {
    next({ name: 'login', query: { to: to.path } });
  } else if (to.matched.some(record => record.meta.requireAdmin) && !store.state.user.is_staff) {
    next({ name: 'home'})
  } else {
    // store.commit('setActive', to.meta.active)
    // store.commit('setDesktop', to.meta.desktop)
    next()
  }
})

export default router
