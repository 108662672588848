<template>
  <Disclosure as="nav" class="bg-gray-800" v-slot="{ open }">
    <div class="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
      <div class="relative flex h-16 items-center justify-between">
        <div class="absolute inset-y-0 left-0 flex items-center sm:hidden">
          <!-- Mobile menu button-->
          <DisclosureButton class="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
            <span class="sr-only">Open main menu</span>
            <Bars3Icon v-if="!open" class="block h-6 w-6" aria-hidden="true" />
            <XMarkIcon v-else class="block h-6 w-6" aria-hidden="true" />
          </DisclosureButton>
        </div>
        <div class="flex flex-1 gap-8 items-center justify-center sm:items-stretch sm:justify-start">
          <router-link :to="{name: 'home'}" class="flex flex-shrink-0 items-center">
            <img class="block h-8 w-auto lg:hidden" src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=500" alt="Your Company" />
            <img class="hidden h-8 w-auto lg:block" src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=500" alt="Your Company" />
          </router-link>
          <!-- SEARCH -->
          <div class="lg:w-2/5 lg:pl-0 md:ml-0 md:w-5/12 w-full sm:px-6 px-4 justify-between" >
            <div
                v-on:click="open = !open"
                class="lg:w-3/12 md:w-2/12 w-2/12 justify-start items-center cursor-pointer md:hidden"
            >
              <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-9 w-9 duration-200 text-sky-blue"
                  viewBox="0 0 20 20"
                  fill="currentColor"
              >
                <path
                    fill-rule="evenodd"
                    d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                    clip-rule="evenodd"
                />
              </svg>
            </div>

            <div class="bg-white flex rounded-md items-center pr-2">
              <input
                  ref="searchInput"
                  class="md:w-full w-9/12 relative search-input bg-light-blue rounded-md border-0 px-4 py-2 text-tiny focus:outline-none"
                  v-model.trim="searchTerm"
                  type="text"
                  placeholder
                  @focusin="toggleResults(true)"
                  @focusout="toggleResults(false)"
                  @keydown.esc="toggleResults(false)"
                  @keyup.enter="goToFirstResult"
              />
              <MagnifyingGlassIcon class="h-6 aspect-square"></MagnifyingGlassIcon>
            </div>

            <div
                class="absolute z-10 bg-white lg:w-2/5 md:w-5/12 w-full rounded-md shadow-lg">
              <div v-show="showResults" class="w-full absolute z-20 bg-white md:ml-0 ml-12 pt-4 pb-2 rounded-md shadow-xl max-h-40 overflow-y-hidden" @click="searchTerm = ''">
                <div v-if="filtered_players.length" class="w-full flex flex-col">
                  <router-link v-for="player in filtered_players" :key="player.id" :to="{name: 'player', params: {id: player.id}}" class="w-full py-1 px-4 text-sm text-gray-400 uppercase hover:bg-gray-50 hover:cursor-pointer">
                    {{ player.last_name }} {{ player.first_name }}
                  </router-link>
                </div>
                <div v-else class="w-full py-1 px-4 text-sm text-gray-300 uppercase">NO RESULT</div>
              </div>
            </div>

          </div>
          <div class="hidden sm:ml-6 sm:block">
            <div class="flex space-x-4">
              <router-link :to="{name: 'home'}" class="text-gray-300 hover:bg-gray-700 hover:text-white rounded-md px-3 py-2 text-tiny font-medium">Home</router-link>
              <router-link v-if="user.is_superuser" :to="{name: 'users'}" class="text-gray-300 hover:bg-gray-700 hover:text-white rounded-md px-3 py-2 text-tiny font-medium">Team</router-link>
            </div>
          </div>
          <div class="hidden sm:ml-6 sm:block">
            <div class="flex space-x-4">
              <button class="text-gray-300 hover:bg-gray-700 hover:text-white rounded-md px-3 py-2 text-tiny font-medium" @click="openAddPlayer">Add player</button>
            </div>
          </div>
        </div>
        <div class="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
          <p class="rounded-full bg-gray-800 p-1 text-tiny text-gray-400">
            {{user.first_name}}
          </p>

          <!-- Profile dropdown -->
          <Menu as="div" class="relative ml-3">
            <div>
              <MenuButton class="flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                <span class="sr-only">Open user menu</span>
                <img class="h-8 w-8 rounded-full" :src="user.image" alt="" />
              </MenuButton>
            </div>
            <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
              <MenuItems class="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <MenuItem v-slot="{ active }">
                  <router-link :to="{name: 'my-profile'}" :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']">My profile</router-link>
                </MenuItem>
                <MenuItem v-slot="{ active }">
                  <button @click="logout" :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700 w-full text-left']">Sign out</button>
                </MenuItem>
              </MenuItems>
            </transition>
          </Menu>
        </div>
      </div>
    </div>

    <DisclosurePanel class="sm:hidden">
      <div class="space-y-1 px-2 pb-3 pt-2">
        <DisclosureButton v-for="item in navigation" :key="item.name" as="a" :href="item.href" :class="[item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white', 'block rounded-md px-3 py-2 text-base font-medium']" :aria-current="item.current ? 'page' : undefined">{{ item.name }}</DisclosureButton>
      </div>
    </DisclosurePanel>
  </Disclosure>
  <!-- Add String Modal-->
  <TransitionRoot appear :show="addPlayerOpen" as="template">
    <Dialog as="div" @close="closeAddPlayer" class="relative z-10">
      <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black bg-opacity-25" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto overflow-x-hidden">
        <div
            class="flex min-h-full items-center justify-end text-center"
        >
          <TransitionChild
              as="template"
              enter="transform transition ease-in-out duration-700"
              enter-from="translate-x-full"
              enter-to="translate-x-0 z-50"
              leave="transform transition ease-in-out duration-700"
              leave-from="translate-x-0"
              leave-to="translate-x-full"
          >
            <DialogPanel
                class="h-screen w-full max-w-md transform overflow-y-auto rounded-l-xl bg-white p-6 text-left align-middle custom-shadow transition-all"
            >
              <DialogTitle
                  as="h1"
                  class="text-3xl p-4 leading-6 text-gray-900"
              >
                Add player
              </DialogTitle>
              <div class="px-4 py-8 flex flex-wrap gap-6">

                <div class="text-left flex-grow text-sm py-2 px-4 rounded-lg border bg-white custom-shadow text-dark">
                  <div class="pb-2">
                    <h1 class="text-sm font-light text-gray-400">Name</h1>
                  </div>
                  <input
                      v-model="new_player.last_name"
                      type="text"
                      class="text-tiny outline-none w-full px-3 py-2 bg-white"
                  />
                </div>

                <div class="text-left flex-grow text-sm py-2 px-4 rounded-lg border bg-white custom-shadow text-dark">
                  <div class="pb-2">
                    <h1 class="text-sm font-light text-gray-400">Firstname</h1>
                  </div>
                  <input
                      v-model="new_player.first_name"
                      type="text"
                      class="text-tiny outline-none px-3 py-2 bg-white"
                  />
                </div>

                <div class="text-left flex-grow text-sm py-2 px-4 rounded-lg border bg-white custom-shadow text-dark">
                  <div class="pb-2">
                    <h1 class="text-sm font-light text-gray-400">Email</h1>
                  </div>
                  <input
                      v-model="new_player.email"
                      type="text"
                      class="text-tiny outline-none px-3 py-2 bg-white"
                  />
                </div>

                <div class="text-left flex-grow text-sm py-2 px-4 rounded-lg border bg-white custom-shadow text-dark">
                  <div class="pb-2">
                    <h1 class="text-sm font-light text-gray-400">Phone</h1>
                  </div>
                  <input
                      v-model="new_player.phone"
                      type="text"
                      class="text-tiny outline-none px-3 py-2 bg-white"
                  />
                </div>

                <div class="text-left flex-grow text-sm py-2 px-4 rounded-lg border bg-white custom-shadow text-dark">
                  <div class="pb-2">
                    <h1 class="text-sm font-light text-gray-400">Notes</h1>
                  </div>
                  <textarea
                      v-model="new_player.notes"
                      type="text"
                      class="text-tiny outline-none px-3 py-2 bg-white w-full"
                  />
                </div>

              </div>

              <div class="px-4 w-full flex justify-end">
                <button
                    type="button"
                    class="inline-flex justify-center rounded-md border border-transparent bg-dark px-4 py-2 text-white"
                    @click="createPlayer"
                >
                  Add player
                </button>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import {Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot} from "@headlessui/vue";
import { Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { Bars3Icon, BellIcon, XMarkIcon, MagnifyingGlassIcon} from '@heroicons/vue/24/outline'
import { defineComponent, ref, computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import router from "@/router";

const store = useStore()
const searchTerm = ref('')
const showResults = ref(false)
const searchInput = ref();
const addPlayerOpen = ref(false)
const new_player = ref({
  first_name: '',
  last_name: '',
  email: '',
  phone: '',
  ranking: '',
  notes: ''
})

const navigation = [
  { name: 'Home', href: {name: 'home'}, current: true },
  { name: 'Team', href: {name: 'users'}, current: false },
]

const user = computed(() => {
  return store.state.user
})

const players = computed(() => {
  return store.state.players
})

const filtered_players = computed(() => {
  let result = searchTerm.value === '' ? players.value :
      players.value.filter((player) => player.last_name?.toLowerCase().includes(searchTerm.value.toLowerCase()) || player.first_name?.toLowerCase().includes(searchTerm.value.toLowerCase()))
  return result.slice(0, 5)
})

// Add player
function closeAddPlayer() {
  addPlayerOpen.value = false
}
function openAddPlayer() {
  addPlayerOpen.value = true
}
async function createPlayer() {
  await store.dispatch('addPlayer', new_player.value)
  closeAddPlayer()
  new_player.value = {
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    ranking: '',
    notes: ''
  }
  await router.push({name: 'player', params: {id: store.state.player.id}})
}

const logout = async () => {
  await store.commit('logout')
  await router.push({name: 'login'})
}

const toggleResults = (status) => {
  setTimeout(() => {
    if (!status) searchTerm.value = '';
    showResults.value = status;
  }, 200);
};

const goToFirstResult = () => {
  if (filtered_players.value[0] && searchTerm.value) {
    router.push({ path: '/players/' + filtered_players.value[0].id + '' });
    searchTerm.value = '';
    showResults.value = false;
    searchInput.value.blur();
  }
};

onMounted(async () => {
  await store.dispatch('getPlayers')
})

</script>
