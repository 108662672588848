<template>
  <div class="flex flex-col h-screen container mx-auto p-10">
    <h1 class="text-3xl font-extralight text-dark">Welcome {{ user.first_name }}!</h1>
  </div>
</template>

<script setup>

import axios from "axios";
import { defineComponent, ref, computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import router from "@/router";

const store = useStore()

const Migrate  = async () => {
  await axios.get('/api/data/', {
  }).then(response => {
    console.log(response)
  })
}

const players = computed(() => {
  return store.state.favs
})

const user = computed(() => {
  return store.state.user
})

onMounted(async () => {
  await store.dispatch('getFavPlayers')
})
</script>
