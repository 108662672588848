<template>
  <!-- Loader -->
  <div
      v-if="this.$store.state.isLoading"
      class="flex items-center justify-around absolute z-50 h-screen w-full backdrop-blur-sm text-center">
    <div class="lds-dual-ring"></div>
  </div>

  <div class="flex flex-col w-full h-screen">
    <nav-bar v-if="isAuthenticated"></nav-bar>
    <div class="flex-1 overflow-y-auto">
      <router-view class="w-full"/>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import NavBar from "@/components/NavBar";

export default {
  components: {NavBar},
  async beforeCreate() {
    document.title = 'TRM'
    await this.$store.commit('initializeStore')

    const token = this.$store.state.jwt

    if (token) {
      axios.defaults.headers.common['Authorization'] = "Token " + token
      await this.$store.dispatch('login')
    } else {
      axios.defaults.headers.common['Authorization'] = ''
    }
  },
  computed: {
    isAuthenticated () {
      return this.$store.state.isAuthenticated
    }
  }
}
</script>

<style>
</style>
