import { createStore } from 'vuex'
import axios from "axios";
import router from "@/router";

export default createStore({
  state: {
    jwt: localStorage.getItem('t'),
    endpoints: {
      obtainJWT: process.env.VUE_APP_BACKEND_URL + '/auth/obtain_token',
      refreshJWT: process.env.VUE_APP_BACKEND_URL + '/auth/refresh_token'
    },
    isAuthenticated: false,
    token: '',
    isLoading: false,
    user: {
      is_superuser: false,
      is_staff: false,
    },
    users: [],
      player: {},
      players: [],
      favs: [],
    errors: [],
  },
  getters: {
  },
  mutations: {
    initializeStore(state) {
      if (localStorage.getItem('t')) {
        state.token = localStorage.getItem('t')
        state.isAuthenticated = true
          axios.defaults.headers.common.Authorization = "Token " + state.token
      } else {
        state.token = ''
        state.isAuthenticated = false
      }
    },
    logout(state) {
      state.user = {}
        state.token = ''
      state.isAuthenticated = false
      state.isAdmin = false
        localStorage.setItem('t', '')
    },
    setIsLoading(state, status) {
      state.isLoading = status
    },
    setIsAuthenticated(state, value) {
      state.isAuthenticated = value
    },
    setActive(state, status) {
      state.active = status
    },
    updateToken(state, newToken){
      localStorage.setItem('t', newToken);
      state.jwt = newToken;
    },
    removeToken(state){
      localStorage.removeItem('t');
      state.jwt = null;
    },
    addToErrors(state, error) {
      state.errors.push(error)
    },
    resetErrors(state) {
      state.errors = []
    },
    setUser(state, user) {
      state.user = user
    },
    setUsers(state, users) {
      state.users = users
    },
      setPlayer(state, player) {
          state.player = player
      },
      setPlayers(state, players) {
          state.players = players
      },
      setFav(state, favs) {
          state.favs = favs
      },
  },
  actions: {
    async login(context, path) {
      await axios.get("/auth/users/me/")
          .then(async response => {
            context.commit('setUser', response.data)
            context.commit('setIsAuthenticated', true)
            if (path) {
              await router.push(path)
            }
          })
          .catch(error => {
              console.log('test')
              context.commit('setIsAuthenticated', true)
            console.log(error)
          })
    },
    async signIn(context, formData) {
      context.commit('resetErrors')
      axios.defaults.headers.common.Authorization = ""
      await axios
          .post("/auth/token/login/", formData)
          .then(async response => {
            await context.commit('setIsAuthenticated', true)
            const token = response.data.auth_token
            await context.commit('updateToken', token)
            axios.defaults.headers.common.Authorization = "Token " + token
            await context.dispatch('login', {name: 'home'})
          })
          .catch(error => {
            if (error.response) {
              for (const property in error.response.data) {
                context.commit('addToErrors',`${error.response.data[property]}`)
              }
            } else {
              context.state.errors.push('Something went wrong. Please try again')

              console.log(JSON.stringify(error))
            }
          })
    },
    async signUp(context, formData) {
      context.commit('resetErrors')
      axios.defaults.headers.common.Authorization = ""
      await axios
          .post("/auth/users/", formData)
          .then(async response => {
            await context.dispatch('signIn', {
              email: formData.email,
              password: formData.password
            })
          })
          .catch(e => {
            console.log(e)
          })
    },
    async getUsers(context) {
      await axios
          .get("/api/users/")
          .then(async response => {
            context.commit('setUsers', response.data)
          })
          .catch(e => {
            console.log(e)
          })
    },
    async getUser(context, id) {
      await axios.get(`/api/users/${id}/`)
          .then(response => {
            context.commit('setUserDetail', response.data)
          })
          .catch(e => {
            console.log(e)
          })
    },
    async deleteUser(context, id) {
      await axios.delete(`/api/users/${id}/`)
          .then(response => {
          })
          .catch(e => {
            console.log(e)
          })
    },
    async createUser(context, formData) {
      await axios.post('api/create-user/', formData)
          .then(response => {
          })
          .catch(error => {
            console.log(error)
          })
    },
    async updateUser(context, {id, formData}) {
      await axios
          .put(`/api/users/${id}/`, formData)
          .then(async response => {
            context.commit('setUser', response.data)
          })
          .catch(e => {
            console.log(e)
          })
    },
    async updateMe(context, formData) {
      await axios
          .put(`/api/users-me/`, formData)
          .then(async response => {
            context.commit('setUser', response.data)
          })
          .catch(e => {
            console.log(e)
          })
    },
    async updateUserDetails(context, {id, formData}) {
      await axios
          .put(`/api/users-details/${id}/`, formData)
          .then(async response => {
            context.commit('setUserDetail', response.data)
          })
          .catch(error => {
            console.log(error)
          })
    },
    async uploadUserPicture(context, {id, formData}) {
      axios
          .post(`/api/users/${id}/picture/`, formData)
          .then(response => {
            context.commit('setUser', response.data)
          })
          .catch(e => {
            console.log(e)
          })
    },
    async resetPwd(context, formData) {
      await axios
          .post("/auth/users/reset_password_confirm/", formData)
          .then(async response => {
            await router.push({name: 'login'})
          })
          .catch(error => {
            console.log(error)
          })
    },
      async getPlayers(context) {
          await axios.get(`/api/players/`)
              .then(response => {
                  context.commit('setPlayers', response.data)
              })
              .catch(e => {
                  console.log(e)
              })
      },
      async getFavPlayers(context) {
          await axios.get(`/api/fav_players/`)
              .then(response => {
                  context.commit('setFav', response.data)
              })
              .catch(e => {
                  console.log(e)
              })
      },
      async getPlayer(context, id) {
          await axios.get(`/api/players/${id}/`)
              .then(response => {
                  context.commit('setPlayer', response.data)
              })
              .catch(e => {
                  console.log(e)
              })
      },
      async deletePlayer(context, id) {
          await axios.delete(`/api/players/${id}/`)
              .then(response => {
              })
              .catch(e => {
                  console.log(e)
              })
      },
      async updatePlayer(context, {id, formData}) {
          await axios.put(`/api/players/${id}/`, formData)
              .then(response => {
                  context.commit('setPlayer', response.data)
              })
              .catch(e => {
                  console.log(e)
              })
      },
      async addPlayer(context, formData) {
          await axios.post(`/api/players/`, formData)
              .then(response => {
                  context.commit('setPlayer', response.data)
              })
              .catch(e => {
                  console.log(e)
              })
      },
      async addRacket(context, formData) {
          await axios.post(`/api/rackets/`, formData)
              .then(async response => {
                  await context.dispatch('getPlayer', formData.player)
              })
              .catch(e => {
                  console.log(e)
              })
      },
      async updateRacket(context, {id, formData}) {
          await axios.put(`/api/rackets/${id}/`, formData)
              .then(async response => {
                  await context.dispatch('getPlayer', formData.player)
              })
              .catch(e => {
                  console.log(e)
              })
      },
      async deleteRacket(context, id) {
          await axios.delete(`/api/rackets/${id}/`)
              .then(async response => {
                  await context.dispatch('getPlayer', context.state.player.id)
              })
              .catch(e => {
                  console.log(e)
              })
      },
      async addString(context, formData) {
          await axios.post(`/api/strings/`, formData)
              .then(async response => {
                  await context.dispatch('getPlayer', context.state.player.id)
              })
              .catch(e => {
                  console.log(e)
              })
      },
      async updateString(context, {id, formData}) {
          await axios.put(`/api/strings/${id}/`, formData)
              .then(async response => {
                  await context.dispatch('getPlayer', context.state.player.id)
              })
              .catch(e => {
                  console.log(e)
              })
      },
      async deleteString(context, id) {
          await axios.delete(`/api/strings/${id}/`)
              .then(async response => {
                  await context.dispatch('getPlayer', context.state.player.id)
              })
              .catch(e => {
                  console.log(e)
              })
      },
  },
  modules: {
  }
})
